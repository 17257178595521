
import './App.css';
import './styles/main.scss'
import AppRoutes from  './routes'

function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
