import React , {useState , useEffect} from 'react'
import { Peduli, Pertahara, Warung} from '../../assets'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Project() {

 const [data , setData] = useState(null)

 useEffect( () => {
    AOS.init({duration : 2000})
 } , [])

  return (
        <div id="project" className='collab'>
        <h2 className='collab-title'>
        <span>Colab-<br />orative</span><br />
        <span>Project</span>
        </h2>

        <h2 className='collab-title-mobile'>
        <span>Colaborative </span>
        <span>Project</span>
        </h2>
    <div className='wrap-collab'>
        <div data-aos="fade-up" className='wrap-card'>
        <div className='card-img-f'>
            <img src={Peduli} alt="" /> 
        </div>
        <div className='card-info'>
            <h2 className='title-info'>Peduli Pangan</h2>
            <p className={`info ${data === 0 ? 'open' : ''}`}>
            Peduli Pangan Desa Kota was our initiative that focused on providing 
            food for those who were affected by the COVID-19 Pandemic. One of the biggest 
            problems caused by the pandemic is food security, in which the food supply 
            chain has been negatively impacted. HARA was able to collaborate with various 
            private companies and NGOs, connecting players in the supply chain, to create the 
            initiative Peduli Pangan: Desa Kota. We do this by sourcing food products from local farmers, 
            opening Soup Kitchens run by volunteers, to supply food to the most vulnerable groups. To date, the Peduli Pangan team has benefited over 99,000 people in 2020 and established around 30 “kitchens” all around Jakarta.
            </p>
            { data === null || data !== 0 ? <button className='more' onClick={() => setData(0)}>[MORE]</button> : null }
        </div>
        </div>
        <div data-aos="fade-up" className='wrap-card'>
        <div className='card-img'>
            <img src={Warung} alt="" /> 
        </div>
        <div className='card-info'>
            <h2 className='title-info'>Warung Tetangga</h2>
            <p className={`info ${data === 1 ? 'open' : ''}`}>
            Warung Tetangga was a collaborative program with the Indonesian Ministry
            of Cooperation and small and medium enterprises 
            (SME) with various other organizations. The goal of this program was 
            to ensure the availability of stock supply as well as to strengthen SME shop 
            owners, especially in adherence to the Indonesian government’s
            policy and campaign in social distancing, cashless payment, driving the digital 
            economy as well as pushing the consumption of Indonesian made products. HARA then works together with volunteers to verify the data and information of each registered SME to then be uploaded on Google Maps to amplify their presence and business.
            </p>
            { data === null || data !== 1 ? <button className='more' onClick={() => setData(1)}>[MORE]</button> : null }
        </div>
        </div>
        <div data-aos="fade-up" className='wrap-card'>
        <div className='card-img'>
            <img src={Pertahara} alt="" /> 
        </div>
        <div className='card-info'>
            <h2 className='title-info'>PERTAHARA</h2>
            <p className={`info ${data === 2 ? 'open' : ''}`}> 
            PERTAHARA is a rural and semi-urban women empowerment and crowd 
            planting movement that strives to enable a group of women to utilize 
            their backyards to carry out profitable agricultural activities to produce 
            additional income for their families. PERTAHARA is also addressing issues of 
            poverty and inequality by creating more job prospects for women through the use of 
            their existing resources (such as their smartphones or existing lands) and pushing forward 
            their role as well as agripreneurs as the main actor in our ecosystem.
            </p>
            { data === null || data !== 2 ? <button className='more' onClick={() => setData(2)}>[MORE]</button> : null }

            <a href='https://www.hara.ag/our-projects' target="_blank" rel="noreferrer" className='btn-pertahara'>Visit Pertahara</a>
        </div>
        </div>
    </div>
    </div>
  )
}

export default Project