import React , {useState} from 'react'


function Story() {

    const [data , setData] = useState(1)

  return (
        <div className='story'>
                <h2 className='story-title'>
                <span>Our</span>
                <br />
                <span>Story</span>
                </h2>

                <div className='story-slider'>
                <div className='story-card'> 
                    <p onMouseEnter={() => setData(1)} className={`story-card-info ${ data === 1 ? 'active' : ''}`}>
                    <span className={`capital ${data === 1 ? 'active' : ''}`}>Y</span>ayasan Harapan Rakyat Berdaulat, also known as HARA Foundation, 
                    was established in 2017 as the umbrella organization that supports two main goals, 
                    the first is for the development acceleration of the agricultural sector and the 
                    second is to provide education and socialization regarding the role of blockchain in creating impact. 
                    Through HARA Foundation, NGOs and other social groups are invited to contribute to creating social impacts-both financially & digitally. 
                    </p>
                </div>
                <div className='story-card'> 
                    <p onMouseEnter={() => setData(2)} className={`story-card-info ${ data === 2 ? 'active' : ''}`}>
                    When the world was hit by the pandemic in 2020, many Indonesians were severely impacted, 
                    be it in terms of health or financial security. To support our vision and missions, 
                    HARA Foundation initiated the Peduli Pangan Movement and Warung Tetangga to secure that people all around 
                    Indonesia have access to food and that SMEs are supported through the use of technology.
                    </p>
                </div>
                <div className='story-card'> 
                    <p onMouseEnter={() => setData(3)} className={`story-card-info ${ data === 3 ? 'active' : ''}`}>
                    Yayasan Harapan Rakyat Berdaulat, also known as HARA Foundation, 
                    was established in 2017 as the umbrella organization that supports two main goals, 
                    the first is for the development acceleration of the agricultural sector and the 
                    second is to provide education and socialization regarding the role of blockchain in creating impact. 
                    Through HARA Foundation, NGOs and other social groups are invited to contribute to creating social impacts-both financially & digitally. 
                    </p>
                </div>
            </div>
        </div>
  )
}

export default Story