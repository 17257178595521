import React , {useState} from 'react'
import { Logo } from '../../assets'

function Navbar() {
  
  const [data , setData] = useState(1)

  const [open , setOpen] = useState(false)

  const [change , setChange] = useState(false)

  const handleClick = (number) => {
    setData(number)
    setOpen(false)
  }

  const onScroll = () => {

    if(!change)
    {
      if(window.scrollY >= 100){
        setChange(true)
      }
    }
    else
    {
      if(window.scrollY === 0)
      {
        setChange(false)
      }
    }
  }

  window.addEventListener('scroll' , onScroll)



  
  return (
    <div className={`navbar ${change ? 'open' : ''}`}>
        <div className='navbar-wrap'>
            <div className='wrap-logo'>
                <img src={Logo} alt="logo hara foundation" />
            </div>
            <div className={`link-wrap ${open === true ? 'open' : ''}`} >
               <a href="/#home" onClick={() => handleClick(1)} className={`link ${data === 1 ? 'active' : ''}`} >Home</a>
               <a href="/#about" onClick={() => handleClick(2)} className={`link ${data === 2 ? 'active' : ''}`} >About</a>
               <a href="/#project" onClick={() => handleClick(3)} className={`link ${data === 3 ? 'active' : ''}`} >Project</a>
               <a href="/#join" onClick={() => handleClick(4)} className={`link ${data === 4 ? 'active' : ''}`} >Join Us</a>
               <a href="/#contact" onClick={() => handleClick(5)} className={`link ${data === 5 ? 'active' : ''}`} >Contact Us</a>
            </div>
            <div className={`hamburger-menu ${open === true ? 'open' : ''}`} onClick={() => setOpen(!open)}>
              <div className='btn-hamburger' />
            </div>
        </div>
    </div>
  )
}

export default Navbar