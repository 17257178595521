import React from 'react'
import {Routes , Route} from 'react-router-dom'
import Home from '../pages/home'

function Index() {
  return (
    <Routes>
        <Route exact path='/' element={<Home />} />
    </Routes>
  )
}

export default Index