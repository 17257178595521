import React from 'react'
import { FB, Hara, IG,  } from '../../assets'

function Footer() {
  return (
    <div id="contact" className='footer'>
        <div className='footer-wrap'>
          <div className='footer-img'>
            <img src={Hara} alt="" />
            <p>FOUNDATION</p>
          </div>

          <div className='wrapper-links'>
            <div className='menu'>
              <h2>Menu</h2>
              <a href="/#home">Home</a>
              <a href="/#about">About Us</a>
              <a href="/#project">Project</a>
              <a href='/#join'>Join Us</a>
              <a href='/#contact'>Contact Us</a>
            </div>
            <div className='contact'>
              <h2>Contact</h2>
              <div className='wrap-contact'>
                <div className='contact-info'>
                  <p>Phone</p>
                  <p>Fax</p>
                  <p>Social Media</p>
                </div>
                <div className='contact-info'>
                  <p>(021) 5202568</p>
                  <p>(021) 5202570</p>
                  <div className='social'> 
                    <a href="https://www.facebook.com/HARACommunity/" target="_blank" rel="noreferrer">
                      <img src={FB} alt="" />
                    </a>
                    {/* <a href='' rel="noreferrer">
                      <img src={twitter} alt="" />
                    </a> */}
                    <a href='https://www.instagram.com/haratoken/' target="_blank" rel="noreferrer">
                      <img src={IG} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className='copy'>
        © 2022 HARA FOUNDATION. All Rights Reserved.
        </p>
    </div>
  )
}

export default Footer