import React from 'react'
import { Globe, Img1, Img2, Img3 , Team } from '../assets'
import Footer from '../components/layout/footer'
import Navbar from '../components/layout/navbar'
import Project from '../components/pages/project'
import Story from '../components/pages/story'

function Home() {
  return (
    <div className='home'>
        <Navbar />
        <div id="home" className='header-home'>
            <div className='header-content'>
                <h2 className='header-content-title'>YAYASAN HARAPAN <br />
                    RAKYAT BERDAULAT</h2>

                <p className='header-content-info'>
                A social & humanitarian organization that combines the utilization of blockchain technology to bring forth sustainable social impacts and
                the acceleration of the Indonesian agriculture ecosystem.
                </p>
            </div>
        </div>
        <div id="about" className='mission'>
          <h2 className='mission-title'><span>Our</span><br /><span>Mission</span></h2>
          <div className='mission-wrap'>
          <div className='mission-slider'>
            <div className='mission-card'>
              <img className='img' src={Img1} alt="" />
              <div className='wrap-info'>
                <p className='info'>
                  Organize social initiatives that accelerate the development & digitalization of farmers and agriculture
                </p>
              </div>
            </div>
            <div className='mission-card'>
              <img className='img' src={Img2} alt="" />
              <div className='wrap-info'>
                <p className='info'>
                Provide humanitarian assistance for impacted communities
                </p>
              </div>
            </div>
            <div className='mission-card'>
              <img className='img' src={Img3} alt="" />
              <div className='wrap-info'>
                <p className='info'>
                Organize education about the social impacts of HARA blockchain technology
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>

        <Story />

        <div className='team'>
          <h2 className='team-title'>Our <br /> Team</h2>
          <div className='team-wrap'>
            <img src={Team} alt="" />
          </div>
        </div>

        <Project />

        <div id="join" className='join'>
          <div className='join-wrap'>
            <div className='img-wrap'>
              <img src={Globe} alt="" />
            </div>
            <div className='content'>
              <h2 className='content-title'>
                Join Us as a Change Maker and Social Contributor!
              </h2>

              <a href='https://forms.office.com/r/25Hnvp6wKa' target="_blank" rel="noreferrer">Join Us {'>'}</a>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Home